import React, { createContext, useState, useEffect } from 'react';

const defaultState = {
  open: false,
};

export const NotificationContext = createContext(defaultState);

export const NotificationProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const showNotification = (product, color, quantity, lang, size) => {
    //console.log('show '+JSON.stringify(product))
    setState({ ...state, open: true , product: product, color: color 
      , quantity: quantity, lang : lang, size : size});
      window.ttq.track('InitiateCheckout', {
        "contents": [
          {
            "content_id": product.slug,
          }
        ]
      });
  };

  const closeNotification = () => {
    setState({ ...state, open: false });
  };

 /* useEffect(() => {
    if (state?.open === true) {
      setTimeout(() => {
        closeNotification();
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);*/

  return (
    <NotificationContext.Provider
      value={{
        state,
        setState,
        showNotification,
        closeNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
