exports.components = {
  "component---src-pages-ar-404-js": () => import("./../../../src/pages/ar/404.js" /* webpackChunkName: "component---src-pages-ar-404-js" */),
  "component---src-pages-ar-about-js": () => import("./../../../src/pages/ar/about.js" /* webpackChunkName: "component---src-pages-ar-about-js" */),
  "component---src-pages-ar-cart-js": () => import("./../../../src/pages/ar/cart.js" /* webpackChunkName: "component---src-pages-ar-cart-js" */),
  "component---src-pages-ar-faq-js": () => import("./../../../src/pages/ar/faq.js" /* webpackChunkName: "component---src-pages-ar-faq-js" */),
  "component---src-pages-ar-get-lang-js": () => import("./../../../src/pages/ar/getLang.js" /* webpackChunkName: "component---src-pages-ar-get-lang-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-item-[id]-js": () => import("./../../../src/pages/ar/item/[id].js" /* webpackChunkName: "component---src-pages-ar-item-[id]-js" */),
  "component---src-pages-ar-order-confirm-js": () => import("./../../../src/pages/ar/orderConfirm.js" /* webpackChunkName: "component---src-pages-ar-order-confirm-js" */),
  "component---src-pages-ar-search-js": () => import("./../../../src/pages/ar/search.js" /* webpackChunkName: "component---src-pages-ar-search-js" */),
  "component---src-pages-ar-shop-[slug]-js": () => import("./../../../src/pages/ar/shop/[slug].js" /* webpackChunkName: "component---src-pages-ar-shop-[slug]-js" */),
  "component---src-pages-ar-shop-v-2-js": () => import("./../../../src/pages/ar/shopV2.js" /* webpackChunkName: "component---src-pages-ar-shop-v-2-js" */),
  "component---src-pages-ar-styling-js": () => import("./../../../src/pages/ar/styling.js" /* webpackChunkName: "component---src-pages-ar-styling-js" */),
  "component---src-pages-ar-support-js": () => import("./../../../src/pages/ar/support.js" /* webpackChunkName: "component---src-pages-ar-support-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-cart-js": () => import("./../../../src/pages/en/cart.js" /* webpackChunkName: "component---src-pages-en-cart-js" */),
  "component---src-pages-en-faq-js": () => import("./../../../src/pages/en/faq.js" /* webpackChunkName: "component---src-pages-en-faq-js" */),
  "component---src-pages-en-get-lang-js": () => import("./../../../src/pages/en/getLang.js" /* webpackChunkName: "component---src-pages-en-get-lang-js" */),
  "component---src-pages-en-get-path-js": () => import("./../../../src/pages/en/getPath.js" /* webpackChunkName: "component---src-pages-en-get-path-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-item-[id]-js": () => import("./../../../src/pages/en/item/[id].js" /* webpackChunkName: "component---src-pages-en-item-[id]-js" */),
  "component---src-pages-en-order-confirm-js": () => import("./../../../src/pages/en/orderConfirm.js" /* webpackChunkName: "component---src-pages-en-order-confirm-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-en-shop-[slug]-js": () => import("./../../../src/pages/en/shop/[slug].js" /* webpackChunkName: "component---src-pages-en-shop-[slug]-js" */),
  "component---src-pages-en-shop-v-2-js": () => import("./../../../src/pages/en/shopV2.js" /* webpackChunkName: "component---src-pages-en-shop-v-2-js" */),
  "component---src-pages-en-styling-js": () => import("./../../../src/pages/en/styling.js" /* webpackChunkName: "component---src-pages-en-styling-js" */),
  "component---src-pages-en-support-js": () => import("./../../../src/pages/en/support.js" /* webpackChunkName: "component---src-pages-en-support-js" */)
}

